$orange: #ff9d01;
$grey: #333333;
$dark-grey: #292b2e;
$light-grey: #9B9B9B;
$lighter-grey: #dfe4ea;
$error-red: #FD4949;

@font-face {
  font-family: Roboto;
  src: url(./font/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-display: swap
}

@font-face {
  font-family: Roboto;
  src: url(./font/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-display: swap
}

@font-face {
  font-family: Roboto;
  src: url(./font/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
  font-display: swap
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: .95rem;
  line-height: 150%;
}

body {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: auto;
}

// ---------- LOGIN ---------- //

.login-window {

  header {
    max-width: 1500px;
    margin: 2rem auto 0 auto;
    padding: 1.5rem;

    .header__logo {
      max-width: 195px;

      svg {
        width: 100%;
      }
    }
  }

  h1 {
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .flex {
    display: flex;
    flex-flow: row wrap;

    &-between {
      justify-content: space-between;
    }

    .item {

      &-66 {
        width: 66%;
      }

      &-26 {
        width: 26%;
      }
    }
  }

  .login_form {
    max-width: 240px;

    .login_icons {
      position: relative;

      label {
        display: none;
      }

      i {
        position: absolute;
        width: 40px;
        height: 100%;
      }

      .user {
        background: url("./img/user.svg") center center no-repeat;
        background-size: 16px;
      }

      .passwd {
        background: url("./img/lock.svg") center center no-repeat;
        background-size: 16px;
      }

      .login_input-field {
        width: 100%;
        margin: .5rem 0;
        padding: .5rem 0 .5rem 2.4rem;
        font-size: .9rem;
        border: solid 1px $dark-grey;
        outline: none;

        &::placeholder {
          color: $light-grey;
          font-weight: 300;
          font-size: .9rem;
        }
      }
    }

    .login_error {
      padding-left: 30px;
      position: relative;
      display: none;

      span {
        color: $error-red;
        font-size: .8rem;
        font-weight: 300;
      }

      &:before {
        content: '';
        background-image: url(./img/error-triangle.svg);
        background-repeat: no-repeat;
        padding: .5rem;
        position: absolute;
        left: 0;
        top: 3px;
        background-size: 20px;
        width: 14px;
        height: 14px;
      }
    }

    .login_submit {
      margin: 1.5rem 0;
      padding: .5rem 2.4rem;
      border: none;
      outline: none;
      background: #000;
      color: #fff;
      font-size: .9rem;
      transition: .3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: $orange;
        transition: .3s ease-in-out;
      }
    }
  }
}

// ---------- HEADER ---------- //

.header {
  max-width: 1500px;
  margin: 2rem auto 0 auto;
  padding: 1.5rem;

  display: flex;
  justify-content: space-between;


  .header__logo {
    max-width: 195px;

    svg {
      width: 100%;
    }
  }

  .header__user {
    display: flex;
    justify-content: center;
    align-items: center;

    .user-name {
      font-weight: 300;
      padding-right: 1.5rem;
    }

    .btn__logout {
      background: black;
      position: relative;
      border: none;
      outline: none;
      width: 32px;
      height: 32px;
      cursor: pointer;
      transition: .3s ease-in-out;

      &::before {
        content: '';
        background-image: url(./img/logout.svg);
        background-repeat: no-repeat;
        padding: .5rem;
        position: absolute;
        left: 8px;
        top: 8px;
        background-size: 16px;
        width: 16px;
        height: 16px;
      }

      &:hover {
        background: $orange;
        transition: .3s ease-in-out;
      }
    }
  }
}

// ---------- SIDENAV ---------- //

.side-nav {
  width: 100px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        text-decoration: none;
        color: #000000;
        font-weight: 300;
        line-height: 180%;
        transition: .3s ease-in-out;

        &:hover {
          color: $orange;
          transition: .3s ease-in-out;
        }
      }

      .active {
        font-weight: 700;
      }
    }
  }
}

// ---------- CONTAINER & CONTENT ---------- //
.hide {
  display: none;
}

.spinner_wrapper {
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    height: 125px;
    width: 125px;
  }
}

.container {
  max-width: 1500px;
  margin: 6rem auto 0;
  padding: 1.5rem;

  &__flex {
    display: flex;
  }
}

.content {
  width: calc(100% - 100px);
  border-left: solid 1px $dark-grey;
  min-height: 65vh;
  padding-left: 2rem;

  &__inner {
    position: relative;

    h1 {
      font-size: 2.2rem;
      font-weight: 700;

      position: absolute;
      top: -6.5rem;
      left: -2rem;
    }
  }
}

// ---------- BTN ---------- //

.btn__add {
  background: black;
  color: white;
  text-decoration: none;
  padding: .5rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;

  position: absolute;
  top: -5px;
  right: 0;

  &::before {
    content: '';
    background-image: url(./img/plus.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 11px;
    background-size: 15px;
    width: 15px;
    height: 15px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}

.btn__save {
  background: black;
  color: white;
  text-decoration: none;
  padding: .5rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;
  border: none;
  outline: none;
  margin-top: 2rem;
  position: relative;

  &::before {
    content: '';
    background-image: url(./img/save.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 11px;
    background-size: 15px;
    width: 15px;
    height: 15px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}

.btn__add-dse-a {
  background: black;
  color: white;
  text-decoration: none;
  padding: .6rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;
  border: none;
  outline: none;
  margin-top: 2rem;
  position: relative;

  &::before {
    content: '';
    background-image: url(./img/plus-file.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 10px;
    background-size: 15px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}

.btn__con-dse {
  background: black;
  color: white;
  text-decoration: none;
  padding: .6rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;
  border: none;
  outline: none;
  margin-top: 2rem;
  position: relative;
  margin-left: 1rem;

  &::before {
    content: '';
    background-image: url(./img/file.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 10px;
    background-size: 15px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}

.btn__show {
  background: black;
  color: white;
  text-decoration: none;
  padding: .6rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;
  border: none;
  outline: none;
  margin-top: 1rem;
  position: relative;

  &::before {
    content: '';
    background-image: url(./img/show.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 12px;
    background-size: 18px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}

.btn__downl {
  display: block;
  z-index: 1;
  background: black;
  color: white;
  text-decoration: none;
  padding: .5rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;
  border: none;
  outline: none;
  margin-top: 2rem;
  position: relative;

  &-disabled {
    display: block;
    z-index: 1;
    background: grey;
    color: white;
    text-decoration: none;
    padding: .5rem 1rem .5rem 2.5rem;
    transition: .3s ease-in-out;
    border: none;
    outline: none;
    margin-top: 2rem;
    position: relative;

    &::before {
      content: '';
      background-image: url(./img/download.svg);
      background-repeat: no-repeat;
      position: absolute;
      left: 11px;
      top: 11px;
      background-size: 15px;
      width: 15px;
      height: 15px;
    }
  }

  &::before {
    content: '';
    background-image: url(./img/download.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 11px;
    background-size: 15px;
    width: 15px;
    height: 15px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}

// ---------- FORM ---------- //

.form__add-user, .form__add-customer, .form__add-project__one, .form__choose_customer {

  label {
    display: block;
    font-weight: 300;
    font-size: .95rem;
  }

  input {
    display: block;
    background: $lighter-grey;
    border: none;
    margin: .2rem 0 .7rem 0;
    min-width: 250px;
    height: 32px;
    outline: unset;
    padding: .3rem .5rem;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    width: 250px;

    display: block;
    background: $lighter-grey;
    margin: .2rem 0 .7rem 0;
    min-width: 250px;
    height: 32px;
    outline: unset;
    padding: .3rem .5rem;

    background-image: url(./img/arrow-down.svg);
    background-position: 95% 50%;
    background-repeat: no-repeat;

    font-size: .85rem;
    color: $light-grey;
  }
}

.form__add-user, .form__add-customer, .form__add-project {
  font-family: Roboto, sans-serif;
  font-size: .95rem;
  line-height: 150%;

  .form__add-project__one {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    input {
      width: 66.66%;
    }

    .btn__save {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  .form__add-project__two {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 3rem;

    .item-66 {
      width: 66.66%;

      h1 {
        display: none;
      }
    }

    .item-33 {
      width: 33.33%;
    }

    input {
      width: 100%;
    }

    label {
      font-weight: 300;
    }

    .add-project__two--inner {
      margin: 0 0 1.4rem 0;

      label {
        display: block;
        font-weight: 300;
        font-size: .95rem;
      }

      input {
        display: block;
        background: $lighter-grey;
        border: none;
        margin: .2rem 0 .7rem 0;
        min-width: 250px;
        height: 32px;
        outline: unset;
        padding: .3rem .5rem;
      }
    }

    .two__update {
      font-weight: 300;
      margin: .7rem 0;
      display: block;
      width: 100%;

      span {
        font-weight: 500;
      }
    }

    .two__span-section {
      font-weight: 300;
    }

    .two__span-section-radio {
      display: inline-block;
    }

    .input-radio__flex {
      display: flex;
    }

    input[type='radio'] {
      width: unset;

      + label {
        margin-right: 1.5rem;
      }
    }

    input[type='radio'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-progress-appearance: inherit;
      border: none;
      background: $lighter-grey;
      height: 26px;
      width: 26px;
      position: relative;
      margin: .3rem 0;
    }

    input[type='radio']:checked:before {
      content: url(./img/x.svg);
      //background: #FD4949;
      position: absolute;
      margin: auto;
      width: 18px;
      height: 18px;
      border: none;
      left: 2px;
      right: 0;
      top: 2px;
      bottom: 0;
    }

    input[type='radio']:focus {
      outline: none;
    }

    input[type='radio'] + label {
      padding-left: .5rem;
      top: -12px;
      position: relative;
      font-weight: 300;
    }
  }

  .form__add-project__three {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 3rem;

    .item-33 {
      width: 33.33%;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-progress-appearance: inherit;
      border: none;
      background: $lighter-grey;
      height: 26px;
      width: 26px;
      position: relative;
      margin: .3rem 0;
    }

    input[type='checkbox']:checked:before {
      content: url(./img/x.svg);
      //background: #FD4949;
      position: absolute;
      margin: auto;
      width: 18px;
      height: 18px;
      border: none;
      left: 2px;
      right: 0;
      top: 2px;
      bottom: 0;
    }

    input[type='checkbox']:focus {
      outline: none;
    }

    input[type='checkbox'] + label {
      padding-left: .5rem;
      top: -12px;
      position: relative;
      font-weight: 300;
    }

    .three_item-33--inner {
      margin-bottom: 2rem;
    }

    span {
      font-size: 1.2rem;
    }

    span + div {
      padding-top: .8rem;
    }

    .span__edit {
      background: #000000;
      color: #ffffff;
      font-size: 1.2rem;
      padding: .2rem .7rem;
      width: 100%;
      display: block;
      margin-bottom: 1.2rem;

      &-section {
        font-size: 1rem;
      }
    }

    .input__edit {
      width: 100%;
      margin-bottom: 1rem;
      border: none;
      background: $lighter-grey;
      font-weight: 300;
      color: $grey;
      font-size: .85rem;
      padding: .7rem;

      &:focus {
        outline: none;
      }
    }
  }
}

.form__edit-dse {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .item-40 {
    width: 34%;
    order: 2;
    border-left: solid 1px $dark-grey;
    padding-left: 2rem;
    max-height: 420px;
    font-weight: 300;
    position: sticky;
    top: 0;

    .txt-bigger {
      font-size: 1.3rem;
      font-weight: 700;
      display: block;
      padding-top: .5rem;
    }

    .txt-bold {
      font-size: 1.1rem;
      font-weight: 500;
      display: block;
      margin-top: 1.25rem;
    }

    .txt p {
      margin-top: .2rem;
    }
  }

  .item-60 {
    width: 60%;
    order: 1;

    .btn__save {
      z-index: 1;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 0;
    }

    .edit__dse-section-wrapper {
      margin-bottom: 8rem;
      position: relative;

      .edit__dse-section:not(:first-child) {
        width: 95%;
        margin: auto 0 auto auto;
      }
    }

    .dse__section-buttonbox {
      position: absolute;
      bottom: -50px;
    }

    .edit__dse-section {
      display: flex;
      justify-content: space-between;
      font-weight: 300;

      .dse__section-counter {
        font-size: 1.5rem;
        font-weight: 700;
        position: absolute;
        left: -40px;
        background: black;
        color: white;
        width: 35px;
        text-align: center;
      }

      .item-del {
        width: 40px;
        order: 2;
      }

      .item-100-x {
        width: calc(100% - 60px);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        ::-webkit-scrollbar-track {
          background: $lighter-grey;
        }

        ::-webkit-scrollbar-thumb {
          background: black;
        }

        .item-100-x {
          width: calc(100% - 200px);
          background: #FD4949;
        }
      }

      .dse__section-checkbox {
        position: relative;
        width: 48%;
        order: 2;

        input[type='checkbox'] {
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-progress-appearance: inherit;
          border: none;
          background: $lighter-grey;
          height: 26px;
          width: 26px;
          position: relative;
          margin: .3rem 0;
        }

        input[type='checkbox']:checked:before {
          content: url(./img/x.svg);
          //background: #FD4949;
          position: absolute;
          margin: auto;
          width: 18px;
          height: 18px;
          border: none;
          left: 2px;
          right: 0;
          top: 2px;
          bottom: 0;
        }

        input[type='checkbox']:focus {
          outline: none;
        }

        input[type='checkbox'] + label {
          padding-left: .5rem;
          top: -12px;
          position: relative;
          font-weight: 300;
        }
      }

      .dse__section-select {
        width: 48%;
        order: 3;

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          border-radius: 0;

          display: block;
          background: $lighter-grey;
          margin: 0;
          min-width: 100%;
          height: 32px;
          outline: unset;
          padding: .3rem .5rem;

          background-image: url(./img/arrow-down.svg);
          background-position: 95% 50%;
          background-repeat: no-repeat;

          font-size: .85rem;
          color: $light-grey;
        }
      }

      .dse__section-input {
        width: 100%;
        order: 1;

        input {
          display: block;
          background: $lighter-grey;
          height: 35px;
          border: none;
          margin: 0 0 .7rem 0;
          min-width: 100%;
          outline: unset;
          padding: 0 .5rem;
          font-size: 1.3rem;
        }
      }

      .dse__section-textarea {
        width: 100%;
        order: 4;
        margin-top: .4rem;

        textarea {
          width: 100%;
          display: block;
          appearance: none;
          -webkit-appearance: none;
          border: none;
          background: $lighter-grey;
          min-height: 150px;
          border-radius: 4px;
          padding: 1rem;
          outline: none;
          resize: none;
          line-height: 1.55;
          font-size: inherit;
          font-family: inherit;
          color: black;
          font-weight: 300;
        }
      }
    }
  }
}

.btn__del {
  background: black;
  color: white;
  text-decoration: none;
  //padding: .6rem 1rem .5rem 2.5rem;
  cursor: pointer;
  transition: .3s ease-in-out;
  border: none;
  outline: none;
  margin-top: 6.15rem;
  position: relative;
  height: 40px;
  width: 100%;

  &::before {
    content: '';
    background-image: url(./img/bin.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 11px;
    top: 12px;
    background-size: 16px;
    width: 18px;
    height: 18px;
  }

  &:hover {
    background: $orange;
    transition: .3s ease-in-out;
  }
}


// ---------- TABLE ---------- //

.table {
  width: 100%;
  border-spacing: 0;

  td {
    font-weight: 300;
    padding: .75rem 1rem;
  }

  tr:nth-of-type(2n+1) {
    background: $lighter-grey;
  }

  th {
    font-size: 1rem;
    padding: .2rem 1rem 1.75rem 1rem;
    text-align: left;
    background: #ffffff;
  }

  &__projects {
    position: relative;
  }

  &__customer {
    position: relative;
  }

  &__user {
    position: relative;
  }

  .td__edit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .btn__edit {
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .3s ease-in-out;
    padding: .5rem;

    &::before {
      content: '';
      background-image: url(./img/edit.svg);
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 10px;
      background-size: 18px;
      width: 18px;
      height: 18px;
    }

    &:hover {
      &::before {
        background-image: url(./img/edit-hover.svg);
        transition: .3s ease-in-out;
      }
    }

    span {
      opacity: 0;
    }
  }
}

// ---------- MEDIA ---------- //

@media (max-width: 750px) {

  .login-window {

    .container {
      margin: 0 auto;
    }

    .flex {
      display: flex;
      flex-flow: row wrap;

      &-between {
        justify-content: space-between;
      }

      .item {

        &-66 {
          width: 100%;
        }

        &-26 {
          width: 100%;
          padding-top: 1.5rem;
        }
      }
    }
  }
}
